import { useEffect, useState } from 'react'
import Porlet from '../../components/Porlet/Porlet'
import getOperations from '../../services/operations/operations'

import styles from './Home.module.css'

const Home = () => {

  const [ data, setData ] = useState([])
  const [ pending, setPending ] = useState(false)

  useEffect(() => {
    setPending(true)
    getOperations()
    .then(data => {
      setData(data)
    })
    setPending(false)
  },[])

let datesInfo = [
  {
    name: "Nº Empreses",
    data: data.numCompanies,
    link: "/empreses",
    type: "info",
    icon: "fa-building"
  },
  {
    name: "Nº Centres",
    data: data.numHeadquarters,
    link: "/centres",
    type: "info",
    icon: "fa-building"
  },
  {
    name: "Nº Usuaris",
    data: data.numUsers,
    link: "/usuaris",
    type: "info",
    icon: "fa-users"
  },
  {
    name: "Nº Elements",
    data: data.numElements,
    link: "/elements",
    type: "info",
    icon: "fa-cogs"
  },
  {
    name: "Nº Tipus Manteniment",
    data: data.numUpkeeps,
    link: "/manteniments",
    type: "info",
    icon: "fa-screwdriver-wrench"
  },
]

let datesAlert = [
  {
    name: "Nº Inspeccions Any Actual",
    data: data.numElementsPlanningActualYear,
    link: "/inspeccions",
    type: "alerta",
    icon: "fa-clipboard"
  },
  {
    name: "Nº Inspeccions Mes Actual",
    data: data.numElementsPlanningActualMonth,
    link: "/inspeccions",
    type: "alerta",
    icon: "fa-clipboard"
  },
  {
    name: "Nº Inspeccions Avui",
    data: data.numElementsPlanningToday,
    link: "/inspeccions",
    type: "alerta",
    icon: "fa-clipboard"
  },
  {
    name: "Nº Inspeccions No Fetes",
    data: data.numElementsPlanningLate,
    link: "/inspeccions",
    type: "alerta",
    icon: "fa-clock"
  },
]

  return (
    <div className={styles.container_home}>
      <div className={styles.container_line}>
        {
          datesInfo.map((element, index) => {
            return <Porlet key={index} data={pending ? pending : element.data } name={element.name} className={styles.portlet} link={element.link} type={element.type} icon={element.icon}></Porlet>
          })
        }
      </div>
      <div className={styles.container_line}>
        {
          datesAlert.map((element, index) => {
            return <Porlet key={index} data={pending ? pending : element.data } name={element.name} className={styles.portlet} link={element.link} type={element.type} icon={element.icon}></Porlet>
          })
        }
      </div>
    </div>
  )
}

export default Home